import React from "react";
import ContactUsPageLegal from "../components/pages/contact-us-legal";

interface ContactProps {}

const ContactUsLegal: React.FC<ContactProps> = () => {
  return <ContactUsPageLegal />;
};

export default ContactUsLegal;
