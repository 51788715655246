import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const ContactUsContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.contact-us-container {
    min-width: 100vw;
    min-height: 100vh;
    & > .contact-us-container-max-width {
      max-width: ${({ theme: { spacing } }: ThemeType) => spacing(100)};
      margin: 0 auto;
      font-family: "utopia-std-display";
      padding: ${({ theme }) => theme.spacing(20, 10, 10, 10)};
      display: flex;
      flex-direction: column;
      gap: ${({ theme: { spacing } }: ThemeType) => spacing(3)};

      & > p {
        color: ${({ theme: { palette } }: ThemeType) => palette.p_black.text};
      }
      & > .state-list {
        & > .single-state {
          display: flex;
          font-weight: 500;
          gap: ${({ theme: { spacing } }: ThemeType) => spacing(1)};
          & > p {
            &:nth-of-type(2n) {
              color: ${({ theme: { palette } }: ThemeType) =>
                palette.p_black.text};
            }
          }
        }
      }
    }
  }
`;
