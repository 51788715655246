import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Box from "@mui/material/Box";
import Seo from "components/common/seo";
import { ContactUsContainer } from "./styles";
import { PrismicRichText } from "@prismicio/react";

interface ContactUsPageProps {}

const ContactUsPageLegal: React.FC<ContactUsPageProps> = () => {
  const data = useStaticQuery(graphql`
    query {
      pageData: allPrismicContactLegal {
        nodes {
          data {
            title {
              richText
            }
            body {
              ... on PrismicContactLegalDataBodyContentParagraph {
                items {
                  single_paragraph {
                    richText
                  }
                }
              }
              ... on PrismicContactLegalDataBodyStateAndNumber {
                id
                items {
                  state_name {
                    richText
                  }
                  state_number
                }
              }
            }
          }
        }
      }
    }
  `);
  const document = data.pageData.nodes[0].data;
  return (
    <>
      <Seo />

      <ContactUsContainer className="contact-us-container">
        <Box className="contact-us-container-max-width">
          <PrismicRichText field={document.title.richText} />
          {document.body[0].items.map((item, index) => {
            return (
              <Box key={index} className="single-paragraph">
                <PrismicRichText field={item.single_paragraph.richText} />
              </Box>
            );
          })}
          <Box className="state-list">
            {document.body[1].items.map((item, index) => {
              return (
                <Box key={index} className="single-state">
                  <PrismicRichText field={item.state_name.richText} />-{" "}
                  {item.state_number}
                </Box>
              );
            })}
          </Box>
        </Box>
      </ContactUsContainer>
    </>
  );
};

export default ContactUsPageLegal;
